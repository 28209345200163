@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
}

@layer base {
  body {
    @apply font-[Poppins];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: contain;
  height: 250px;
  width: 400px;
  border: none;
  outline: none;
  border-radius: 10px;
}
